.clean-btn {
  bottom: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}

.tilt {
  transform: rotate(45deg);
}
